<template>
  <div></div>
</template>

<script>
import reviewApi from '../../api/review'
import { mapActions } from 'vuex'
import productApi from '../../api/product'

export default {
    name: 'productView',
    data(){
        return {
            addToCartLoading: false,
            product: null,
            currentImageIndex: 0,
            currentSkuIndex: 0,
            quantityNum: 0,
            value5: 5,
            value4: 4,
            value3: 3,
            value2: 2,
            value1: 1,
            perStar5: 0,
            perStar4: 0,
            perStar3: 0,
            perStar2: 0,
            perStar1: 0,
            countStar5: 0,
            countStar4: 0,
            countStar3: 0,
            countStar2: 0,
            countStar1: 0,
            reviewStat: null,
        }
    },
    computed:{
        categoryLink(){
            if(this.product == null) return '#'
            return '/category/' + this.product.category.name
        },
        currentCategory(){
            if(this.product == null) return ''
            return this.product.category
        },
        productId(){
            if(this.product == null) return 0
            return this.product.ID
        },
        productName(){
            if(this.product == null) return ''
            return this.product.name
        },
        productMerchantName(){
            if(this.product == null) return ''
            return this.product.merchant.name
        },
        productImages(){
            if(this.product == null) return []
            return this.product.images
        },
        productReviewScore: {
            get(){
                if(this.product == null) return 0
                return this.product.review_score
            },
            set(val){console.log(val)}
        },
        productReviewCount() {
            if(this.product == null) return 0
            return this.product.review_count
        },
        productPrice() {
            if(this.product == null) return 0
            if(this.product.skus.length < this.currentSkuIndex + 1) return 0
            return this.product.skus[this.currentSkuIndex].price
        },
        productMemberPrice() {
            if(this.product == null) return 0
            if(this.product.skus.length < this.currentSkuIndex + 1) return 0
            return this.product.skus[this.currentSkuIndex].member_price
        },
        productSkuName(){
            if(this.product == null) return ''
            if(this.product.skus.length < this.currentSkuIndex + 1) return ''
            return this.product.skus[this.currentSkuIndex].name
        },
        productSkus(){
            if(this.product == null) return []
            var skus = this.product.skus
            return skus.sort((a, b) => a.price - b.price)
        }
    },
    created(){
        this.init()
    },
    methods: {
        ...mapActions('cart', [
            'addGood'
        ]),
        init(){
            let id = this.$route.params.productId
            productApi.getProductById(id).then(resp=>{
                this.product = resp
                this.getProductReviewStat()
            })
        },
        setImageIndex(index){
            this.currentImageIndex = index
            this.$refs.carousel.setActiveItem(index)
        },
        onImageChange(newIndex, oldIndex){
            console.log(newIndex, oldIndex)
            this.currentImageIndex = newIndex
        },
        skuChoose(index){
            this.currentSkuIndex = index
        },
        addToCartClick(){
            this.addToCartLoading = true
            console.log(this.currentSkuIndex, this.quantityNum)
            var sku = this.productSkus[this.currentSkuIndex]
            var good = {
                key: this.productId + '-' + sku.ID,
                product: this.product,
                sku: sku,
                amount: this.quantityNum,
                sub_total: sku.price * this.quantityNum,
                sub_member_total: sku.member_price * this.quantityNum
            }
            this.addGood({
                good: good
            })
            this.addToCartLoading = false
        },
        getProductReviewStat(){
            reviewApi.getProductReviewStat(this.productId).then(resp=>{
                console.log(resp)
                this.countStar5 = resp.star_5
                this.countStar4 = resp.star_4
                this.countStar3 = resp.star_3
                this.countStar2 = resp.star_2
                this.countStar1 = resp.star_1
                this.perStar5 = Math.floor(resp.star_5 * 100 / resp.total)
                this.perStar4 = Math.floor(resp.star_4 * 100 / resp.total)
                this.perStar3 = Math.floor(resp.star_3 * 100 / resp.total)
                this.perStar2 = Math.floor(resp.star_2 * 100 / resp.total)
                this.perStar1 = Math.floor(resp.star_1 * 100 / resp.total)
            })
        }
    }
}
</script>

<style>
.el-carousel__item {
    background-color: #fff !important;
}
.image-checked{
    border: 2px solid #000;
}
.image-unchecked{
    border: 2px solid #DCDFE6;
}

</style>