import axios from 'axios'
import {
 review_create_url,
 review_get_by_order_id_url,
 review_get_product_stat_url
} from './urls'

var reviewApi = {
    create(token, review){
        return new Promise((resolve, reject)=>{
            axios.post(review_create_url, review, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(resp)
                }
            }).catch(err=>{
                console.log(err)
                reject(err)
            })
        })
    },
    getByOrderId(token, orderId){
        var url = review_get_by_order_id_url.format({
            orderId: orderId
        })
        return new Promise((resolve, reject)=>{
            axios.get(url, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(resp)
                }
            }).catch(err=>{
                console.log(err)
                reject(err)
            })
        })
    },
    getProductReviewStat(productId){
        var url = review_get_product_stat_url.format({
            productId: productId
        })
        return new Promise((resolve, reject)=>{
            axios.get(url).then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    resolve(resp.data.result)
                } else {
                    reject(resp)
                }
            }).catch(err=>{
                console.log(err)
                reject(err)
            })
        })
    }
}

export default reviewApi